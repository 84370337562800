var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('data-mobil', {
    attrs: {
      "datas": _vm.datas
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }